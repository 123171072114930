import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { Helmet } from "react-helmet";
import Navbar from "./scenes/Navbar";
import EPKNavbar from "./scenes/EPKNavbar";
import DotGroup from "./scenes/DotGroup";
import Landing from "./scenes/Landing";
import EPKLanding from "./scenes/EPKLanding";
import Store from "./scenes/Store";
import useMediaQuery from "./hooks/useMediaQuery";
import YourFavicon from "./assets/favicon.ico";
import BandPhoto1 from "./assets/bandphoto.jpg";
import BandPhoto2 from "./assets/bandphoto2.JPG";
import BandPhoto3 from "./assets/IMG_3214.JPG";
import Iframe from "react-iframe";
import OneSheet from "./assets/STRANGE-LOT-ONE-SHEET.pdf";
import LevitationP from "./assets/levitation2024.jpeg";
import TourPoster from "./assets/tour-poster.jpg"

function App() {
  const [selectedPage, setSelectedPage] = useState("home");
  const [isTopOfPage, setIsTopOfPage] = useState(true);
  const isAboveMediumScreens = useMediaQuery("(min-width:1060px)");
  const [isEPKRoute, setIsEPKRoute] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY === 0) {
        setIsTopOfPage(true);
        setSelectedPage("home");
      }
      if (window.scrollY !== 0) setIsTopOfPage(false);
    };
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  useEffect(() => {
    // Check if the current path is "/epk" or "/epksb"
    setIsEPKRoute(
      window.location.pathname === "/epk" ||
        window.location.pathname === "/epksb"
    );
  }, []);

  return (
    <div className="app">
      <Helmet>
        <title>STRANGE LOT</title>
        <link rel="icon" href={YourFavicon} />
      </Helmet>
      <Router>
        <div className="mx-auto md:h-full">
          {isEPKRoute ? (
            <EPKNavbar
              selectedPage={selectedPage}
              setSelectedPage={setSelectedPage}
            />
          ) : (
            <Navbar
              isTopOfPage={isTopOfPage}
              selectedPage={selectedPage}
              setSelectedPage={setSelectedPage}
            />
          )}
          {isEPKRoute ? (
            <>
              <EPKLanding setSelectedPage={setSelectedPage} />
              <div className="mx-auto md:h-full">
                <div className="container mx-auto px-4">
                  <div className="mb-8 mt-4">
                    <h3 className="text-xl font-bold font-gridstar mb-4">
                      <span className="bg-amber-300 p-1 text-black">
                        PRIVATE Stream
                      </span>
                    </h3>
                    <div>
                      <Iframe
                        url={
                          window.location.pathname === "/epk"
                            ? "https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/playlists/1802005084%3Fsecret_token%3Ds-vZ4WnlRm7Ta&color=%23ff5500&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true"
                            : "https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/playlists/1803813100%3Fsecret_token%3Ds-0GZJSE1rFI4&color=%23ff5500&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true"
                        }
                        width="100%"
                        height="300"
                        scrolling="no"
                        allow="autoplay"
                      />
                    </div>
                    <div className="py-8">
                      <div className="mb-8" id="bio">
                        <h2 className="text-2xl font-gridstar font-bold mb-4">
                          <span className="bg-amber-300 p-1 text-black">
                            Bio
                          </span>
                        </h2>
                        <p className="font-monda">
                          STRANGE LOT began as the bedroom project of
                          singer/guitarist Dominic Mena in Phoenix before moving
                          to Austin in 2019. The band has grown into the present
                          lineup of Dominic Mena, Tim Lormor, bass guitarist
                          Levi Murray, and Jordan Fitzpatrick on synthesizer and
                          guitar.
                        </p>
                        <p className="font-monda">
                          The new self-titled album was produced by Alex Maas of
                          The Black Angels, with mixing by Jim Eno of Spoon and
                          mastered by Howie Weinberg. STRANGE LOT has opened for
                          The Jesus and Mary Chain, The Brian Jonestown
                          Massacre, The Black Angels, Kikagaku Moyo, & more.
                          <br />
                          <br />
                          The band has built momentum in 2024 touring with
                          Boogarins, culminating in a performance at Austin’s
                          Levitation Festival on the headlining stage with The
                          Black Angels. Looking ahead, 2025 brings a spring tour
                          and the release of a new album on the horizon,
                          continuing the band’s journey of crafting immersive,
                          psychedelic soundscapes.
                        </p>
                        <br />
                        <h2 className="text-2xl font-gridstar font-bold mb-4">
                          <span className="bg-amber-300 p-1 text-black">
                            Album Notes
                          </span>
                        </h2>
                        <p className="font-monda text-amber-300 font-bold">
                          Bruce Adams - author,{" "}
                          <a
                            href="https://utpress.utexas.edu/9781477321201/youre-with-stupid/"
                            className="text-amber-300 underline"
                          >
                            You're with Stupid
                          </a>
                          :
                        </p>

                        <p className="font-monda">
                          "...This is neither straight-up garage rock nor
                          psychedelia by the books. Thematically, beginning with
                          “All I Know,” the album moves from an entrance into
                          the unknown, dealing with life’s challenges, the
                          processes of observation, note-taking, and personal
                          growth and emergence into a less fearful view of the
                          future. The romanticism of “Nobody Seems to Do the
                          Math” is muffled by synth washes and Mena’s reverbed
                          voice, moving forward over a shuffling drumbeat. The
                          dark lyrics of “Hatin’ You” are counterbalanced by the
                          other-dimensionally American-Bandstand sounds.
                        </p>
                        <br></br>

                        <p className="font-monda">
                          A squall opens “Timeline Drops,” which moves into
                          claustrophobic echoes as Mena repeats, “Are we the
                          same?”. The upbeat “5AM” is powered by
                          straightforward, driving guitars. “Inside Out” pops
                          into the album like an Italian movie theme composed
                          and performed by an AI-generated Nina Rota. The
                          ambient interlude “Silent Vault” strikes a similar
                          cinematic vibe. The synth spray that opens” Go Have a
                          Dream” gives way to guitar twang, only to gurgle
                          across the soundfield. Strange Lot uses and abuses the
                          tools at hand to sonically portray the morning’s
                          sunbeams cutting through dirty windows and the
                          apprehensions of half-remembered dreams."
                        </p>
                        {/* <br></br>
                        <p className="font-monda">Track Listing:</p>
                        <p>
                          All I Know<br />
                          Always Alone<br />
                          It’s Always You<br />
                          Timeline Drops<br />
                          5AM<br />
                          Inside Out<br />
                          Nobody Seems To Do the Math<br />
                          Eyes for Ya<br />
                          Go Have a Dream<br />
                          Never Gonna Happen<br />
                          The Many Faces<br />
                          Hatin’ You<br />
                          Silent Vault<br />
                          Not Afraid Anymore
                        </p> */}
                      </div>

                      <div className="mb-8">
                        <h3 className="text-2xl font-bold font-gridstar mb-4">
                          <span
                            className="bg-amber-300 p-1 text-black"
                            id="video"
                          >
                            Video
                          </span>
                        </h3>
                        <div className="flex flex-wrap justify-center ">
                          <div>
                            <iframe
                              className={
                                isAboveMediumScreens
                                  ? ""
                                  : "h-[236px] w-[420px]"
                              }
                              width="800"
                              height="450"
                              src="https://www.youtube.com/embed/kHt_STv-ROg?si=IvRb5VbRLBDzDFxG"
                              title="YouTube video player"
                              frameBorder="0"
                              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                              referrerPolicy="strict-origin-when-cross-origin"
                              allowFullScreen
                            ></iframe>
                          <br></br>
                        
                          <div>
                          </div>
                            <iframe
                              className={
                                isAboveMediumScreens
                                  ? ""
                                  : "h-[236px] w-[420px]"
                              }
                              width="800"
                              height="450"
                              src="https://www.youtube.com/embed/CmkshpeZRq8?si=_pqWFGSmqDRmTD8k"
                              title="YouTube video player"
                              frameBorder="0"
                              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                              referrerPolicy="strict-origin-when-cross-origin"
                              allowFullScreen
                            ></iframe>

                        <br></br>
                          </div>

                          {/* Add more videos as needed */}
                        </div>
                      </div>
                      <div>
                        <div className="flex flex-wrap">
                          <div className="w-full md:w-1/2">
                            <div className="mb-4">
                              <div className="mb-10">
                                <h3 className="text-xl font-bold font-gridstar mb-4">
                                  <span className="bg-amber-300 p-1 text-black">
                                    Highlights
                                  </span>
                                </h3>
                                <p className="font-monda">
                                  Direct support: The Jesus and Mary Chain, The
                                  Brian Jonestown Massacre, Kikagaku Moyo, Night
                                  Beats
                                </p>
                                
                                <div className="w-full sm:w-full md:w-1/2 lg:w-1/2 mb-4 text-center"><br/>
                                <a
                                  href="https://livesessions.npr.org/videos/strange-lot-the-horror-kutx-pop-up-at-levitation"
                                  className=" text-amber-300 font-monda"
                                >
                                 SPRING TOUR 2025
                                </a>
                                  <img
                                    src={TourPoster}
                                    alt="tour-poster"
                                    className="w-full h-full"
                                  />
                                </div>
                                <br />
                                <a
                                  href="https://livesessions.npr.org/videos/strange-lot-the-horror-kutx-pop-up-at-levitation"
                                  className="underline text-amber-300 font-monda "
                                >
                                  NPR Live Session - LEVITATION
                                </a>
                                <br />
                                <br />
                                <div className="w-full sm:w-1/2 md:w-1/2 lg:w-1/3 mb-4 ">
                                  <img
                                    src={LevitationP}
                                    alt="Photo 2"
                                    className="w-full h-auto"
                                  />
                                </div>
                                <a
                                  href="https://www.seetickets.us/event/The-Black-Angels-Mdou-Moctar-Boogarins/610337"
                                  className="underline text-amber-300 font-monda"
                                >
                                  LEVITATION 2024 w/ THE BLACK ANGELS, MDOU
                                  MOCTAR & BOOGARINS
                                </a>
                              </div>
                            </div>
                            <div className="mb-4">
                              <h3 className="text-xl font-bold font-gridstar mb-4">
                                <span
                                  className="bg-amber-300 p-1 text-black"
                                  id="photos"
                                >
                                  Photos
                                </span>
                              </h3>
                              <div className="flex flex-wrap">
                                <div className="w-full sm:w-1/2 md:w-1/2 lg:w-1/3 pr-11 mb-4">
                                  <img
                                    src={BandPhoto3}
                                    alt="Photo 3"
                                    className="w-full h-auto"
                                  />
                                </div>
                                <div className="w-full sm:w-1/2 md:w-1/2 lg:w-1/3 mb-4">
                                  <img
                                    src={BandPhoto2}
                                    alt="Photo 2"
                                    className="w-full h-auto"
                                  />
                                </div>
                                {/* <div className="w-full sm:w-1/2 md:w-[50%] lg:w-full mb-4">
                                  <img src={BandPhoto1} alt="Photo 2" className="w-full h-auto" />
                                </div> */}
                              </div>
                              <div className="flex justify-center">
                                <a
                                  href="https://drive.google.com/drive/folders/1EAsFtqrpQ21VRoxCkMgQZ9niR3SHxz0W?usp=sharing"
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  <button className="mt-4 bg-white text-black font-gridstar px-4 py-2 rounded-full hover:bg-blue-600">
                                    Download Photos
                                  </button>
                                </a>
                              </div>
                            </div>
                          </div>
                          <div
                            className={`w-full md:w-1/2 ${
                              isAboveMediumScreens ? "px-9" : "mt-4"
                            }`}
                          >
                            <div className="mb-4 ml-auto">
                              <h3 className="text-xl font-bold font-gridstar mb-4">
                                <span className="bg-amber-300 p-1 text-black">
                                  Publicity
                                </span>
                              </h3>
                              <p className="font-monda">
                                "[Strange Lot] allow themselves to take it easy
                                on their songs, giving moods the time and space
                                to roll along in their natural way."
                                <br />-{" "}
                                <a
                                  href="https://consequence.net/2015/05/stream-strange-lots-debut-album-another-mind/"
                                  className="underline text-amber-300 font-monda"
                                >
                                  Consequence of Sound
                                </a>
                              </p>
                              <br />
                              <p className="font-monda">
                                "Bridges garage psych and shoegaze into a hazy
                                rainbow."
                                <br />-{" "}
                                <a
                                  href="https://www.brooklynvegan.com/strange-lot/"
                                  className="underline text-amber-300 font-monda"
                                >
                                  Brooklyn Vegan
                                </a>
                              </p>
                              <br />
                              <p className="font-monda">
                                "Austin newcomers Strange Lot blend hazy garage
                                sounds into their Western-sun-scorched take on
                                psych rock"
                                <br />-{" "}
                                <a
                                  href="https://kutx.org/sessions-interviews/studio1a/strange-lot-2-24-20/"
                                  className="underline text-amber-300 font-monda"
                                >
                                  KUTX
                                </a>
                              </p>
                            </div>
                            <a
                              href={OneSheet}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <button className="mt-4 bg-white mb-5 text-black font-gridstar px-4 py-2 rounded-full hover:bg-blue-600">
                                Download One Sheet
                              </button>
                            </a>
                          </div>
                        </div>
                      </div>
                      <div>
                        <h3 className="text-xl font-bold font-gridstar mb-4">
                          Contact
                        </h3>
                        <p className="font-monda">SSTRANGELOT@GMAIL.COM</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          ) : (
            <>
              <Landing setSelectedPage={setSelectedPage} />
              {isAboveMediumScreens && (
                <DotGroup
                  selectedPage={selectedPage}
                  setSelectedPage={setSelectedPage}
                />
              )}
              <Store />
            </>
          )}
        </div>
      </Router>
    </div>
  );
}

export default App;
