import useMediaQuery from "../hooks/useMediaQuery";
import MindstateVinyl from "../assets/vinyl1.jpg";
import MeltTee from "../assets/shirt-melt.jpeg";
import EchoTee from "../assets/echoshirt-rachel2.JPG";
import GodsCassette from "../assets/godsclods1.jpg";
import AnotherMind from "../assets/AnotherMind.jpg";
import Stickers from "../assets/stickers.jpg";
import TourPoster from "../assets/tour-poster.jpg";
import { motion } from "framer-motion";
import YouTube from "react-youtube";

const VideoEmbed = ({ videoIds }) => {
  const isAboveLarge = useMediaQuery("(min-width: 1060px)");
  const opts = {
    height: "200",
    width: "350",
    playerVars: {
      autoplay: 1,
    },
  };

  return (
    <div
      className={`grid grid-cols-1 md:grid-cols-2 gap-20 ${
        isAboveLarge ? "" : ""
      }`}
    >
      {videoIds.map((videoId) => (
        <motion.div
          key={videoId}
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true, amount: 0.5 }}
          transition={{ duration: 0.8 }}
          variants={{
            hidden: { opacity: 0, x: -50 },
            visible: { opacity: 1, x: 0 },
          }}
        >
          <div className="w-full">
            <iframe
              width={opts.width}
              height={opts.height}
              src={`https://www.youtube.com/embed/${videoId}`}
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
          </div>
        </motion.div>
      ))}
    </div>
  );
};

const Store = () => {
  const isAboveLarge = useMediaQuery("(min-width:900px)");
  const openLink = (url) => {
    window.open(url, "_blank");
  };

  const videoIds = [ "2hqqBRUxqqQ", "Odqt51IXkQ8", "qhszP41dtPw"];

  return (
    <section id="store" className="pt-0 pb-0">
      {/* Add the YouTube video above the STORE section */}
      <div className="flex justify-center items-center mb-10">
        <iframe
          width={isAboveLarge ? "1150" : "550"}
          height={isAboveLarge ? "650" : "310"}
          src="https://www.youtube.com/embed/kHt_STv-ROg?si=XFoFvKoHTpeNSvLk"
          title="Strange Lot - Live Video"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        ></iframe>
      </div>

      <div
        className={`flex flex-col items-center ${
          isAboveLarge ? "pt-0" : ""
        } align-top md:flex-col md:justify-start md:gap-15 sm:align-top`}
      >
        <motion.div
          className={`md:w-full md:text-center ${isAboveLarge ? "mt-14" : ""}`}
          initial="hidden"
          whileInView={isAboveLarge ? "visible" : null}
          viewport={{ once: true, amount: isAboveLarge ? 0.5 : 1 }}
          transition={{ duration: isAboveLarge ? 0.3 : 0 }}
          variants={
            isAboveLarge
              ? { hidden: { opacity: 0, y: 40 }, visible: { opacity: 1, y: 0 } }
              : {}
          }
        >
          <p
            className={`font-integralCF md:text-5xl  ${
              isAboveLarge ? "text-5xl" : "text-4xl"
            } text-center`}
          >
            <span className="border-b-2 inline-block">STORE</span>
          </p>
        </motion.div>

        <div
          className={`md:flex justify-center mt-8 ${
            isAboveLarge
              ? "grid grid-cols-2 gap-30"
              : "flex flex-row gap-8 w-[80%]"
          }`}
        >
          {/* FIRST ITEM */}
          <motion.div
            className={`md:w-full ${isAboveLarge ? "sm:w-1/2 " : "w-full"}`}
            initial="hidden"
            whileInView={isAboveLarge ? "visible" : null}
            viewport={{ once: true, amount: isAboveLarge ? 0.5 : 1 }}
            transition={{ duration: isAboveLarge ? 0.3 : 0 }}
            variants={
              isAboveLarge
                ? {
                    hidden: { opacity: 0, y: 50 },
                    visible: { opacity: 1, y: 0 },
                  }
                : {}
            }
          >
            <div className="flex flex-col items-center">
              <div
                className={`relative h-50 ${
                  isAboveLarge ? "w-3/5" : "w-full"
                } `}
              >
                <img alt="MindStateVinyl" src={MindstateVinyl} className="" />
              </div>
              <button
                className={`hover:text-black hover:bg-amber-300 bg-offWhite  p-2 text-black font-monda text-semibold text-[14px] mb-10 ${
                  isAboveLarge ? "mt-3" : "mt-4"
                }`}
                onClick={() =>
                  openLink("https://strangelot.bandcamp.com/album/mindstate")
                }
              >
                12" Blue & Black Galaxy Vinyl
              </button>
            </div>
          </motion.div>

          {/* SECOND ITEM */}
          <motion.div
            className={`md:w-full ${isAboveLarge ? "sm:w-1/2" : "w-full"}`}
            initial="hidden"
            whileInView={isAboveLarge ? "visible" : null}
            viewport={{ once: true, amount: isAboveLarge ? 0.5 : 1 }}
            transition={{ duration: isAboveLarge ? 0.3 : 0 }}
            variants={
              isAboveLarge
                ? {
                    hidden: { opacity: 0, y: 50 },
                    visible: { opacity: 1, y: 0 },
                  }
                : {}
            }
          >
            <div className="flex flex-col items-center">
              <div
                className={`relative h-50 ${
                  isAboveLarge ? "w-3/5" : "w-full"
                } `}
              >
                <img
                  alt="gods-clods-cassette"
                  src={GodsCassette}
                  className=""
                />
              </div>
              <button
                className={`hover:text-black hover:bg-amber-300 bg-offWhite  p-2 text-black font-monda text-semibold text-[14px] ${
                  isAboveLarge ? "mt-3" : "mt-4"
                }`}
                onClick={() =>
                  openLink("https://strangelot.bandcamp.com/album/gods-clods")
                }
              >
                Gods & Clods (Cassette)
              </button>
            </div>
          </motion.div>
        </div>

        <div
          className={`md:flex justify-center mt-1 ${
            isAboveLarge
              ? "grid grid-cols-2 gap-30"
              : "flex flex-row gap-8 w-[80%]"
          }`}
        >
          {/* SECOND ITEM */}

          <motion.div
            className={`md:w-full ${isAboveLarge ? "sm:w-1/2" : "w-full"}`}
            initial="hidden"
            whileInView={isAboveLarge ? "visible" : null}
            viewport={{ once: true, amount: isAboveLarge ? 0.5 : 1 }}
            transition={{ duration: isAboveLarge ? 0.3 : 0 }}
            variants={
              isAboveLarge
                ? {
                    hidden: { opacity: 0, y: 50 },
                    visible: { opacity: 1, y: 0 },
                  }
                : {}
            }
          >
            <div className="flex flex-col items-center">
              <div
                className={`relative h-50 ${
                  isAboveLarge ? "w-3/5" : "w-full"
                } `}
              >
                <img alt="Stickers" src={Stickers} className="" />
              </div>
              <button
                className={`hover:text-black hover:bg-amber-300 bg-offWhite  p-2 text-black font-monda text-semibold text-[14px] ${
                  isAboveLarge ? "mt-3 mb-6" : "mt-6 mb-5"
                }`}
                onClick={() =>
                  openLink(
                    "https://strangelot.bandcamp.com/merch/glow-in-the-dark-sticker-3-pack"
                  )
                }
              >
                Glow In The Dark Stickers
              </button>
            </div>
          </motion.div>

          <motion.div
            className={`md:w-full ${isAboveLarge ? "sm:w-1/2" : "w-full"}`}
            initial="hidden"
            whileInView={isAboveLarge ? "visible" : null}
            viewport={{ once: true, amount: isAboveLarge ? 0.5 : 1 }}
            transition={{ duration: isAboveLarge ? 0.3 : 0 }}
            variants={
              isAboveLarge
                ? {
                    hidden: { opacity: 0, y: 50 },
                    visible: { opacity: 1, y: 0 },
                  }
                : {}
            }
          >
            <div className="flex flex-col items-center">
              <div className={`h-50 ${isAboveLarge ? "w-3/5" : "w-full"}`}>
                <img alt="anothermind" src={AnotherMind} className="" />
              </div>
              <button
                className={`hover:text-black hover:bg-amber-300 bg-offWhite  p-2 text-black font-monda text-semibold text-[14px] ${
                  isAboveLarge ? "mt-3" : "mt-6"
                }`}
                onClick={() =>
                  openLink(
                    "https://strangelot.bandcamp.com/album/another-mind-2"
                  )
                }
              >
                Another Mind LP (CD)
              </button>
            </div>
          </motion.div>
        </div>

        <div
          className={`md:flex justify-center mt-1 ${
            isAboveLarge
              ? "grid grid-cols-2 gap-30"
              : "flex flex-row gap-8 w-[80%]"
          }`}
        >
          {/* Melt Tee */}
          <motion.div
            className={`md:w-full ${isAboveLarge ? "sm:w-1/2 " : "w-full"}`}
            initial="hidden"
            whileInView={isAboveLarge ? "visible" : null}
            viewport={{ once: true, amount: isAboveLarge ? 0.5 : 1 }}
            transition={{ duration: isAboveLarge ? 0.3 : 0 }}
            variants={
              isAboveLarge
                ? {
                    hidden: { opacity: 0, y: 50 },
                    visible: { opacity: 1, y: 0 },
                  }
                : {}
            }
          >
            <div className="flex flex-col items-center">
              <div
                className={`relative h-50 ${
                  isAboveLarge ? "w-3/5" : "w-full"
                } `}
              >
                <img
                  alt="MeltTee"
                  src={MeltTee}
                  className="w-full h-full object-contain"
                />
              </div>
              <button
                className={`hover:text-black hover:bg-amber-300 bg-offWhite  p-2 text-black font-monda text-semibold text-[14px] ${
                  isAboveLarge ? "mt-3" : "mt-6"
                }`}
                onClick={() =>
                  openLink(
                    "https://strangelot.bandcamp.com/merch/strange-lot-melt-logo-t-shirt"
                  )
                }
              >
                Melt Black Tee
              </button>
            </div>
          </motion.div>

          {/* Echo Tee */}
          <motion.div
            className={`md:w-full ${isAboveLarge ? "sm:w-1/2" : "w-full"}`}
            initial="hidden"
            whileInView={isAboveLarge ? "visible" : null}
            viewport={{ once: true, amount: isAboveLarge ? 0.5 : 1 }}
            transition={{ duration: isAboveLarge ? 0.3 : 0 }}
            variants={
              isAboveLarge
                ? {
                    hidden: { opacity: 0, y: 50 },
                    visible: { opacity: 1, y: 0 },
                  }
                : {}
            }
          >
            <div className="flex flex-col items-center">
              <div
                className={`relative h-50 ${
                  isAboveLarge ? "w-3/5" : "w-full"
                } `}
              >
                <img
                  alt="EchoTee"
                  src={EchoTee}
                  className="w-full h-full object-contain"
                />
              </div>
              <button
                className={`hover:text-black hover:bg-amber-300 bg-offWhite  p-2 text-black font-monda text-semibold text-[14px] ${
                  isAboveLarge ? "mt-3" : "mt-6"
                }`}
                onClick={() =>
                  openLink("https://strangelot.bandcamp.com/merch/echo-t-shirt")
                }
              >
                Echo Tee
              </button>
            </div>
          </motion.div>
        </div>
      </div>

      {/* *****VIDEO SECTION BELOW***** */}
      <div
        id="video"
        className={`flex mb-20 flex-col items-center ${
          isAboveLarge ? "md:-mt-1" : ""
        } align-top md:flex-col md:justify-start md:gap-16 sm:align-top`}
      >
        <motion.div
          className={`md:w-full md:text-center ${
            isAboveLarge ? "mt-14" : "mt-4"
          }`}
        >
          <p
            className={`font-gridstar font-semibold mb-10 ${
              isAboveLarge ? "text-7xl" : "text-4xl"
            } text-center`}
          >
            <span className="border-b-2 inline-block">VIDEO</span>
          </p>
        </motion.div>
        {/* ^^^^^ VIDEO TITLE ^^^ */}

        <iframe
          className={isAboveLarge ? "" : "h-[236px] w-[420px]"}
          width="800"
          height="450"
          src="https://www.youtube.com/embed/CmkshpeZRq8?si=_pqWFGSmqDRmTD8k"
          title="YouTube video player"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          referrerPolicy="strict-origin-when-cross-origin"
          allowFullScreen
        ></iframe>

        <VideoEmbed videoIds={videoIds} />
      </div>

      {/* *****LIVE SECTION BELOW***** */}
      <div
        id="live"
        className={`flex flex-col items-center ${
          isAboveLarge ? "md:-mt-1" : ""
        } align-top md:flex-col md:justify-start md:gap-16 sm:align-top`}
      >
        <motion.div
          className={`md:w-full md:text-center ${
            isAboveLarge ? "mt-14" : "mt-4"
          }`}
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true, amount: 0.5 }}
          transition={{ duration: 0.8 }}
          variants={{
            hidden: { opacity: 0, x: -50 },
            visible: { opacity: 1, x: 0 },
          }}
        >
          <p
            className={`font-integralCF ${
              isAboveLarge ? "text-7xl" : "text-4xl"
            } text-center`}
          >
            <span className="border-b-2 inline-block mb-10">LIVE</span>
          </p>
        </motion.div>

        {/* TOUR POSTER IMAGE */}

        <motion.div
          className={`md:w-full ${isAboveLarge ? "sm:h-1/2" : "w-full"}`}
          initial="hidden"
          whileInView={isAboveLarge ? "visible" : null}
          viewport={{ once: true, amount: isAboveLarge ? 0.5 : 1 }}
          transition={{ duration: isAboveLarge ? 0.3 : 0 }}
          variants={
            isAboveLarge
              ? {
                  hidden: { opacity: 0, y: 50 },
                  visible: { opacity: 1, y: 0 },
                }
              : {}
          }
        >
          <div className="flex flex-col items-center">
            <div
              className={`relative h-50 ${isAboveLarge ? "w-1/2" : "w-1/2"} `}
            >
              <img alt="tour-poster" src={TourPoster} className="" />
            </div>
          </div>
        </motion.div>
        {/* LIVE SHOWS CONTENT */}
        <div
          className={`md:flex md:flex-col justify-center mt-1 mb-40 ${
            isAboveLarge ? "flex flex-col gap-20" : ""
          }`}
        >
          <motion.div
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true, amount: 0.5 }}
            transition={{ duration: 0.8 }}
            variants={{
              hidden: { opacity: 0, x: -50 },
              visible: { opacity: 1, x: 0 },
            }}
          >
            <div className="font-monda ml-3 mb-14">
              <p
                className={`text-6xl font-bold mb-1 ${
                  isAboveLarge ? "" : "text-[30px]"
                }`}
              >
                MARCH 27TH
              </p>
              <p
                className={`text-3xl font-bold mb-1 text-amber-300 ${
                  isAboveLarge ? "" : "text-xl"
                }`}
              >
                W/ LA DISCORDIA
              </p>
              <p className={`text-xl ${isAboveLarge ? "" : "text-base"}`}>
                9pm / MONA BAR OF MODERN ART / EL PASO, TX
              </p>
              <a
                className={`text-amber-300 text-xl ${
                  isAboveLarge ? "" : "text-base"
                }`}
                href="https://www.eventbrite.com/e/strange-lot-mona-bar-of-modern-art-tickets-1223074632149?aff=oddtdtcreator"
                target="_blank"
              >
                Tickets
              </a>
            </div>
          </motion.div>

          <motion.div
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true, amount: 0.5 }}
            transition={{ duration: 0.8 }}
            variants={{
              hidden: { opacity: 0, x: -50 },
              visible: { opacity: 1, x: 0 },
            }}
          >
            <div className="font-monda ml-3 mb-14">
              <p
                className={`text-6xl font-bold mb-1 ${
                  isAboveLarge ? "" : "text-[30px]"
                }`}
              >
                MARCH 28TH
              </p>
              <p
                className={`text-3xl font-bold mb-1 text-amber-300 ${
                  isAboveLarge ? "" : "text-xl"
                }`}
              >
                SUPPORT TBA
              </p>
              <p className={`text-xl ${isAboveLarge ? "" : "text-base"}`}>
                9pm / OWLS CLUB / TUCSON, AZ
              </p>
              <a
                className={`text-amber-300 text-xl ${
                  isAboveLarge ? "" : "text-base"
                }`}
                href="https://www.eventbrite.com/e/strange-lot-owls-club-tickets-1223083578909?aff=oddtdtcreator"
                target="_blank"
              >
                Tickets
              </a>
            </div>
          </motion.div>

          <motion.div
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true, amount: 0.5 }}
            transition={{ duration: 0.8 }}
            variants={{
              hidden: { opacity: 0, x: -50 },
              visible: { opacity: 1, x: 0 },
            }}
          >
            <div className="font-monda ml-3 mb-14">
              <p
                className={`text-6xl font-bold mb-1 ${
                  isAboveLarge ? "" : "text-[30px]"
                }`}
              >
                MARCH 29TH
              </p>
              <p
                className={`text-3xl font-bold mb-1 text-amber-300 ${
                  isAboveLarge ? "" : "text-xl"
                }`}
              >
                W/ SOFT PALMS, WINDOWS
              </p>
              <p className={`text-xl ${isAboveLarge ? "" : "text-base"}`}>
                9pm / PERMANENT RECORDS ROADHOUSE / LOS ANGELES, CA
              </p>
              <a
                className={`text-amber-300 text-xl ${
                  isAboveLarge ? "" : "text-base"
                }`}
                href="https://link.dice.fm/r212dc9e392b"
                target="_blank"
              >
                Tickets
              </a>
            </div>
          </motion.div>

          <motion.div
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true, amount: 0.5 }}
            transition={{ duration: 0.8 }}
            variants={{
              hidden: { opacity: 0, x: -50 },
              visible: { opacity: 1, x: 0 },
            }}
          >
            <div className="font-monda ml-3 mb-14">
              <p
                className={`text-6xl font-bold mb-1 ${
                  isAboveLarge ? "" : "text-[30px]"
                }`}
              >
                MARCH 30TH
              </p>
              <p
                className={`text-3xl font-bold mb-1 text-amber-300 ${
                  isAboveLarge ? "" : "text-xl"
                }`}
              >
                SUPPORT TBA
              </p>
              <p className={`text-xl ${isAboveLarge ? "" : "text-base"}`}>
                9pm / THUNDERBIRD LOUNGE / PHOENIX, AZ
              </p>
              <a
                className={`text-amber-300 text-xl ${
                  isAboveLarge ? "" : "text-base"
                }`}
                href="https://www.ticketweb.com/event/strange-lot-thunderbird-lounge-tickets/14214323?pl=thunderbird"
                target="_blank"
              >
                Tickets
              </a>
            </div>
          </motion.div>

          <motion.div
            className={`md:w-full md:text-center ${
              isAboveLarge ? "mt-14" : "mt-4"
            }`}
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true, amount: 0.5 }}
            transition={{ duration: 0.8 }}
            variants={{
              hidden: { opacity: 0, x: -50 },
              visible: { opacity: 1, x: 0 },
            }}
          >
            <p
              className={`font-integralCF ${
                isAboveLarge ? "text-5xl" : "text-4xl"
              } text-center`}
            >
              <span className="border-b-2 inline-block mb-10">PAST SHOWS</span>
            </p>
          </motion.div>

          <motion.div
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true, amount: 0.5 }}
            transition={{ duration: 0.8 }}
            variants={{
              hidden: { opacity: 0, x: -50 },
              visible: { opacity: 1, x: 0 },
            }}
          >
            <div className="font-monda ml-3 mb-14">
              <p
                className={`text-4xl font-bold mb-1 text-gray-400 ${
                  isAboveLarge ? "" : "text-[20px]"
                }`}
              >
                October 27
              </p>
              <p
                className={`text-2xl font-bold mb-1 text-gray-400 ${
                  isAboveLarge ? "" : "text-lg"
                }`}
              >
                W/ BOOGARINS
              </p>
              <p
                className={`text-lg text-gray-400 ${
                  isAboveLarge ? "" : "text-base"
                }`}
              >
                9pm / LONESOME ROSE / SAN ANTONIO, TX
              </p>
            </div>
          </motion.div>

          <motion.div
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true, amount: 0.5 }}
            transition={{ duration: 0.8 }}
            variants={{
              hidden: { opacity: 0, x: -50 },
              visible: { opacity: 1, x: 0 },
            }}
          >
            <div className="font-monda ml-3 mb-14">
              <p
                className={`text-4xl font-bold mb-1 text-gray-400 ${
                  isAboveLarge ? "" : "text-[20px]"
                }`}
              >
                October 28
              </p>
              <p
                className={`text-2xl font-bold mb-1 text-gray-400 ${
                  isAboveLarge ? "" : "text-lg"
                }`}
              >
                W/ BOOGARINS
              </p>
              <p
                className={`text-lg text-gray-400 ${
                  isAboveLarge ? "" : "text-base"
                }`}
              >
                9pm / RUBBER GLOVES / DENTON, TX
              </p>
            </div>
          </motion.div>

          <motion.div
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true, amount: 0.5 }}
            transition={{ duration: 0.8 }}
            variants={{
              hidden: { opacity: 0, x: -50 },
              visible: { opacity: 1, x: 0 },
            }}
          >
            <div className="font-monda ml-3 mb-14">
              <p
                className={`text-4xl font-bold mb-1 text-gray-400 ${
                  isAboveLarge ? "" : "text-[20px]"
                }`}
              >
                October 29
              </p>
              <p
                className={`text-2xl font-bold mb-1 text-gray-400 ${
                  isAboveLarge ? "" : "text-lg"
                }`}
              >
                W/ BOOGARINS
              </p>
              <p
                className={`text-lg text-gray-400 ${
                  isAboveLarge ? "" : "text-base"
                }`}
              >
                9pm / LAST CONCERT CAFE / HOUSTON, TX
              </p>
            </div>
          </motion.div>

          <motion.div
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true, amount: 0.5 }}
            transition={{ duration: 0.8 }}
            variants={{
              hidden: { opacity: 0, x: -50 },
              visible: { opacity: 1, x: 0 },
            }}
          >
            <div className="font-monda ml-3 mb-14">
              <p
                className={`text-3xl font-bold mb-1 text-gray-400 ${
                  isAboveLarge ? "" : "text-[20px]"
                }`}
              >
                October 30
              </p>
              <p
                className={`text-2xl font-bold mb-1 text-gray-400 ${
                  isAboveLarge ? "" : "text-lg"
                }`}
              >
                W/ BOOGARINS
              </p>
              <p
                className={`text-lg text-gray-400 ${
                  isAboveLarge ? "" : "text-base"
                }`}
              >
                9pm / SLOW & STEADY / DALLAS, TX
              </p>
            </div>
          </motion.div>

          <motion.div
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true, amount: 0.5 }}
            transition={{ duration: 0.8 }}
            variants={{
              hidden: { opacity: 0, x: -50 },
              visible: { opacity: 1, x: 0 },
            }}
          >
            <div className="font-monda ml-3 mb-14">
              <p
                className={`text-4xl font-bold mb-1 text-gray-400 ${
                  isAboveLarge ? "" : "text-[20px]"
                }`}
              >
                October 31
              </p>
              <p
                className={`text-2xl font-bold mb-1 text-gray-400 ${
                  isAboveLarge ? "" : "text-lg"
                }`}
              >
                LEVITATION W/ THE BLACK ANGELS, MDOU MOCTAR & BOOGARINS
              </p>
              <p
                className={`text-lg text-gray-400 ${
                  isAboveLarge ? "" : "text-base"
                }`}
              >
                9pm / STUBBS / AUSTIN, TX
              </p>
            </div>
          </motion.div>
        </div>

        <div>
          <div className="flex flex-col mt-3 mb-5">
            <p className="font-monda text-center mb-1">Contact</p>
            <p className="font-monda">MGMT@STRANGELOT.COM</p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Store;
